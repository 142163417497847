.fesbClassExecutionPlanCard {
    display: grid;
    grid-template-columns: 50% 50%;
    background: linear-gradient(
        90deg,
        var(--colorWhiteDarkBackground) 0%,
        var(--colorWhiteDarkBackground) 50%,
        var(--colorWhiteBackground) 100%
    );
    max-width: 450px;
    box-shadow: 0 0 7px 4px #aaa;
    margin: 20px 0;
    overflow: hidden;
    align-items: center;
    & > .fesbClassExecutionPlanCardLeft {
        & > .fesbClassExecutionPlanCardTitle {
            font-size: 1.2rem;
            font-weight: bold;
            padding: 10px;
            text-transform: uppercase;
        }
        & > .fesbClassExecutionPlanCardProfessors {
            display: flex;
            flex-direction: column;
            padding: 10px;
            & > a {
                text-decoration: none;
                font-size: 0.8rem;
                font-weight: bold;
                color: var(--colorPrimary);
            }
        }
    }
    & > .fesbClassExecutionPlanCardRight {
        & > .fesbClassExecutionPlanCardContent {
            text-transform: lowercase;
            margin-left: 1rem;
        }
    }
}

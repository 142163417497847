.fesbSearchBox {
    height: 100%;
    width: 100%;
    padding: 10px 5px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    box-sizing: border-box;
    background-color: var(--colorGreyLight);
    // box-shadow: 0 0 15px 4px var(--colorGreyDark);
    z-index: 3;
    transition: height 0.4s;
    &.minimized {
        height: 50px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        overflow: hidden;
    }
    & > .fesbSearchBoxInput {
        width: 96%;
        height: 45px;
        font-family: 'Exo 2', sans-serif;
        font-weight: bold;
        padding: 6px;
        outline: none;
        margin-bottom: 1rem;
        box-sizing: border-box;
        border: 2px solid var(--colorPrimary);
        background: linear-gradient(
            0deg,
            var(--colorWhiteDarkBackground) 0%,
            var(--colorWhiteBackground) 20%,
            var(--colorWhiteBackground) 80%,
            var(--colorWhiteDarkBackground) 100%
        );
        &:focus {
            border: 4px solid var(--colorPrimaryLight);
            box-shadow: 0 0 10px 4px var(--colorPrimaryLight);
        }
    }
}

.fesbCourseListElementContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    box-sizing: border-box;
    transition: height 0.4s;
    width: 95%;
    margin: 5px 0;
    box-shadow: 0 0 6px 2px var(--colorPrimaryDark);
    & > .fesbListElementMeasurer {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--colorWhiteBackground);
        box-sizing: border-box;
        width: 100%;
        & > .fesbCourseListElement {
            display: grid;
            width: 100%;
            grid-template-columns: auto 20px;
            align-items: center;
            font-family: 'Exo 2', sans-serif;
            font-weight: 700;
            text-decoration: none;
            box-sizing: border-box;
            min-height: 30px;
            padding: 5px;
            text-transform: uppercase;
            cursor: pointer;
            color: var(--colorPrimaryDark);
            background: linear-gradient(
                0deg,
                var(--colorWhiteDarkBackground) 0%,
                var(--colorWhiteBackground) 50%,
                var(--colorWhiteDarkBackground) 100%
            );
            &:hover {
                color: var(--colorWhiteBackground);
                background: linear-gradient(0deg, var(--colorPrimaryDark) 0%, var(--colorPrimaryLight) 50%, var(--colorPrimaryDark) 100%);
            }
            &.specialization {
                width: 100%;
                margin: 4px 0 0 0;
                grid-template-columns: 50px 1fr;
            }
        }
    }
}

.fesbEducatorsView {
    & > .fesbSearchBoxAndContent {
        & > .fesbEducatorInfoPanel {
            display: grid;
            $navigationHeight: 60px;
            grid-template-rows: $navigationHeight auto;
            padding: 0;
            width: 100%;
            position: relative;
            flex-direction: row-reverse;
            @media only screen and (max-width: 1000px) {
                flex-direction: column;
            }
            & > .fesbEducatorTabs {
                width: 100%;
                height: $navigationHeight;
                background: linear-gradient(0deg, var(--colorPrimaryDark) 0%, var(--colorPrimaryLight) 100%);
                display: flex;
                flex-direction: row;
                justify-content: space-around;
                border-top-right-radius: 0;
                color: var(--colorWhiteBackground);
                font-size: 1.2rem;
                font-weight: 700;
                text-transform: uppercase;
                overflow: hidden;
                z-index: 5;
                align-items: center;
                & > .fesbEducatorTab {
                    cursor: pointer;
                    position: relative;
                    bottom: 0px;
                    transition: bottom 0.2s;
                    padding: 30px 0;
                    &:hover {
                        bottom: 4px;
                        text-shadow: 0 0 20px white;
                    }
                }
            }
            & > .fesbEducatorFixedInfo {
                width: 300px;
                height: calc(100% - 60px);
                border-radius: 15px;
                padding: 10px 5px 10px;
                display: flex;
                position: absolute;
                left: 0;
                top: $navigationHeight;
                justify-content: flex-start;
                flex-direction: column;
                align-items: center;
                border-top-right-radius: 15px;
                border-bottom-right-radius: 15px;
                box-sizing: border-box;
                // background-color: var(--colorWhiteDarkBackground);
                // box-shadow: 0 0 15px 4px var(--colorGreyDark);
                z-index: 0;
                @media only screen and (max-width: 1000px) {
                    position: relative;
                    top: unset;
                    width: 100%;
                    box-sizing: border-box;
                    padding-left: 35vw;
                    min-height: calc(37.5vw + 40px);
                }
                & > .fesbEducatorTitle {
                    font-size: 1rem;
                }
                & > .fesbEducatorName {
                    font-size: 1.2rem;
                    font-weight: 700;
                    color: var(--colorPrimary);
                }
                & > .fesbEducatorType {
                    font-size: 1.2rem;
                }
                & > .fesbLargeUserFrame {
                    margin-top: 1rem;

                    @media only screen and (max-width: 1000px) {
                        position: absolute;
                        left: 15px;
                        top: 15px;
                    }
                    & > img {
                        width: 160px;
                        height: 200px;
                        border: 4px solid var(--colorPrimary);
                        border-radius: 10px;
                        margin-bottom: 1rem;
                        object-fit: cover;
                        @media only screen and (max-width: 1000px) {
                            width: 30vw;
                            height: 37.5vw;
                        }
                    }
                }
            }
            & > .fesbEducatorScrollingInfo {
                width: calc(100% - 300px);
                height: 100%;
                padding-left: 300px;
                overflow-x: hidden;
                @media only screen and (max-width: 1000px) {
                    width: 100%;
                    overflow-x: unset;
                    padding-left: unset;
                    height: auto;
                }
                & > .fesbEducatorContent {
                    width: 100%;
                    height: auto;
                    box-sizing: border-box;
                    overflow-y: auto;
                    padding: 10px 15px 10px;
                    @media only screen and (max-width: 1000px) {
                        padding: 10px 15px;
                    }
                    & > h2 {
                        color: var(--colorPrimary);
                    }
                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }
    }
}
.fesbEducatorFixedListItem {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    padding: 5px 15px;
    box-sizing: border-box;
    & > i {
        font-size: 1.2rem;
        margin-right: 15px;
    }
}
.fesbEducatorParticipationRow {
    display: grid;
    grid-template-columns: 1.7fr 3fr 1fr;
    align-items: center;
    padding: 3px 0;
    width: 100%;
    & > * {
        padding: 3px;
    }
    & > a {
        font-weight: bold;
        color: var(--colorPrimary);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
    & > div > a {
        font-weight: bold;
        color: var(--colorPrimary);
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
.fesbEducatorParticipationTitle {
    text-transform: uppercase;
    color: var(--colorPrimary);
}
.bibirLink {
    text-decoration: none;
    font-weight: bold;
    color: var(--colorPrimary);
    &:hover {
        text-decoration: underline;
    }
}

.fesbLanguagePickerContainer {
    color: white;
    margin: 15px;
    font-size: 1.5rem;
    position: relative;
    cursor: pointer;
    & > i {
        padding: 8px;
    }
    & > .fesbLanguagePickerDropDown {
        box-shadow: 0 0 15px 4px var(--colorGreyLight);
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 38px;
        list-style: none;
        margin: 0;
        right: 0;
        padding: 0 5px;
        border-radius: 5px;
        background: var(--colorWhiteBackground);
        overflow: hidden;
        transition: height 0.4s;
        &.closed {
            height: 0px;
        }
        &.open {
            height: 74px;
        }
        & > .fesbLanguagePickerOption {
            &:hover {
                text-shadow: 0 0 5px var(--colorPrimaryLight);
            }
            text-decoration: none;
            color: var(--colorPrimary);
            font-weight: bold;
            font-size: 1.2rem;
            padding: 7px;
        }
    }
}

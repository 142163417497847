.fesbStaticHeader {
    display: flex;
    flex-direction: row;
    position: fixed;
    top: 0;
    width: 100%;
    max-width: 100vw;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: var(--colorGrey);
    z-index: 21;
    box-shadow: 0 0 10px black;
    font-family: 'Exo 2', sans-serif;
    & > .fesbStaticMenuHamburger {
        color: white;
        padding: 5px;
    }
    & > .fesbStaticHeaderLinkContainer {
        display: flex;
        height: 100%;
        flex-direction: row;
        & > a {
            padding: 5px 10px;
            box-sizing: border-box;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: var(--colorWhiteBackground);
            border-bottom: 3px solid var(--colorGrey);
            &:hover {
                border-bottom: 3px solid var(--colorPrimary);
            }
        }
    }
    & > .fesbStaticHeaderLinkDropdown {
        width: 100%;
        position: absolute;
        top: 100%;
        height: auto;
        background-color: var(--colorGrey);
        display: flex;
        flex-direction: column;
        height: 0;
        overflow: hidden;
        transition: height 0.4s;
        & > a {
            padding: 5px 10px;
            box-sizing: border-box;
            height: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: var(--colorWhiteBackground);
        }
    }
}

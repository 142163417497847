.fesbCourseHeader {
    height: 60px;
    background: linear-gradient(180deg, var(--colorPrimaryLight) 0%, var(--colorPrimaryDark) 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.1rem;
    font-weight: bold;
    & > i {
        cursor: pointer;
        font-size: 1.5rem;
        margin: 0.5rem;
    }
}

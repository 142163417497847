.fesbAcademicClassesContent {
    $navigationHeight: 60px;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    display: grid;
    grid-template-rows: $navigationHeight auto;
    @media only screen and (max-width: 1000px) {
        display: flex;
        flex-direction: column;
        border-top-right-radius: 0px;
        height: calc(100% - 50px);
    }
    & > .fesbAcademicClassesTabs {
        height: $navigationHeight;
        width: 100%;
        top: 0;
        background: linear-gradient(0deg, var(--colorPrimaryDark) 0%, var(--colorPrimaryLight) 100%);
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        text-align: center;
        color: var(--colorWhiteBackground);
        font-size: 1.2rem;
        overflow: hidden;
        font-weight: 700;
        z-index: 7;
        text-transform: uppercase;
        align-items: center;
        @media only screen and (max-width: 1000px) {
            border-top-right-radius: 0px;
            font-size: 1rem;
            & > * {
                padding: 5px;
            }
        }
        & > .fesbAcademicClassesTab {
            cursor: pointer;
            position: relative;
            bottom: 0px;
            transition: bottom 0.2s;
            padding: 30px 0;
            &:hover {
                text-shadow: 0 0 20px white;
                bottom: 4px;
            }
        }
    }
    & > .fesbAcademicClassesFixedSegment {
        padding: 80px 10px 10px 0px;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        width: 300px;
        flex-direction: column;
        justify-content: flex-start;
        box-sizing: border-box;
        height: 100%;
        @media only screen and (max-width: 1000px) {
            padding: 10px 10px 10px 0px;
            position: static;
            width: 100%;
        }
        & > .fesbAcademicClassesFixedInfoBar {
            width: 100%;
            padding: 3px 0 3px 18px;
            margin: 2px 0;
            justify-content: flex-start;
            box-sizing: border-box;
            display: flex;
            height: auto;
            align-items: center;
            text-transform: uppercase;
            font-weight: 700;
            color: var(--colorPrimaryLight);
            background: linear-gradient(270deg, var(--colorWhiteBackground) 0%, var(--colorWhiteDarkBackground) 100%);
            & > div {
                display: flex;
                align-items: center;
            }
            & > a {
                text-decoration: none;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
    & > .fesbAcademicClassTab {
        padding: 10px 30px 10px 330px;
        height: auto;
        width: 100%;
        box-sizing: border-box;
        overflow-y: auto;
        & > h2 {
            color: var(--colorPrimary);
        }
        @media only screen and (max-width: 1000px) {
            border-top-right-radius: 0px;
            font-size: 1rem;
            padding: 10px 10px 10px 10px;
            overflow-y: unset;
        }
    }
}
.fesbAcademicClassesHeadProfessorsList {
    display: flex;
    flex-direction: column;
}

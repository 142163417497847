.FesbClassExecutionPlanRow {
    font-family: 'Exo 2', sans-serif;
    display: grid;
    grid-template-columns: 13% 29.28% 23.16% 3.23% 3.23% 3.23% 3.23% 3.23% 3.23% 3.23% 3.23% 8.35%;
    font-size: 0.8rem;
    padding: 5px;
    align-items: center;
    justify-content: center;
    & > .smallBox {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
    }
    & > a {
        text-decoration: none;
        font-weight: bold;
        color: var(--colorPrimary);
        font-size: 1rem;
        &:hover {
            text-decoration: underline;
        }
    }
    & > div > a {
        text-decoration: none;
        font-weight: bold;
        font-size: 0.9rem;
        color: var(--colorPrimary);
        &:hover {
            text-decoration: underline;
        }
    }
}
.FesbClassExecutionPlanHeaderRowContainer {
    background: rgb(238, 238, 238);
    color: var(--colorWhiteBackground);
    margin: 5px;
    background: linear-gradient(180deg, var(--colorPrimaryLight) 0%, var(--colorPrimaryDark) 100%);
    font-weight: bold;
    & > .FesbClassExecutionPlanHeaderRow {
        font-size: 0.9rem;
        & > .smallBox {
            display: flex;
            justify-content: center;
        }
    }
    & > .FesbClassExecutionPlanHeaderRowTitle {
        margin-bottom: 0.3rem;
        padding: 5px 15px;
    }
}
.fesbExecutionPlanLegend {
    margin-top: 1rem;
    font-size: 0.7rem;
}
.fesbErrorMessage {
    width: 100%;
    display: flex;
    font-size: 0.6rem;
    color: var(--colorError);
    justify-content: flex-start;
}

.fesbClassUnitsListItem {
    display: grid;
    grid-template-columns: 40px 1fr 60px;
    padding-left: 5px;
    align-items: center;
}
.fesbClassUnitTitle {
    text-transform: uppercase;
    color: var(--colorPrimary);
}

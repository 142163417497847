.fesbSplashCard {
    height: 0;
    width: 0;
    position: absolute;
    display: flex;
    justify-content: center;
    transition: opacity 0.4s, top 0.4s, left 0.4s;
    align-items: center;
    text-decoration: none;
    &.activeCard > .fesbSplashCardContent > .fesbSplashCardOverlay > .fesbSplashCardTitle {
        @media only screen and (min-width: 1000px) {
            font-size: 1.6rem;
        }
    }

    & > .fesbSplashCardContent {
        cursor: pointer;
        box-shadow: 0 0 10px 2px var(--colorPrimaryLight);
        background-color: #00407677;
        width: 100%;
        height: 100%;
        min-width: 42vw;
        min-height: 42vw;
        transition: ease-in-out 0.4s;
        @media only screen and (min-width: 1000px) {
            min-width: 260px;
            min-height: 260px;
        }
        & > .fesbSplashCardOverlay {
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: center;
            background-color: #004076cc;
            align-items: center;
            transition: background-color 0.4s;
            &:hover {
                background-color: #00407655;
            }
            & > .fesbSplashCardTitle {
                color: #cccccc;
                text-transform: uppercase;
                transition: font-size 0.4s;
                font-weight: bold;
                font-family: 'Exo 2', sans-serif;
                font-size: 1rem;
                text-align: center;
                padding: 6px;
            }
        }

        @media only screen and (min-width: 1000px) {
            &:hover {
                box-shadow: 0 0 20px 6px var(--colorPrimaryLight);
                transform: scale(1.1, 1.1);
            }
        }
    }
}
.suppressAnimation {
    opacity: 0;
    transition: none;
    & > .fesbSplashCardContent {
        transition: none;
        & > .fesbSplashCardOverlay {
            & > .fesbSplashCardTitle {
                transition: none;
            }
        }
    }
}

.fesbHeaderBarOption {
    & > .fesbSplashCardContent {
        & > .fesbSplashCardOverlay {
            & > .fesbSplashCardTitle {
                font-size: 1.1rem;
                padding: 0 10px;
            }
        }
        &:hover {
            box-shadow: 0 0 20px 6px var(--colorPrimaryLight);
        }
    }
}
.fesbHeaderBarOptionTest {
    & > .fesbSplashCardContent {
        height: auto;
        width: auto;
        min-width: unset;
        min-height: unset;
        & > .fesbSplashCardOverlay {
            & > .fesbSplashCardTitle {
                font-size: 1.1rem;
                padding: 0 10px;
            }
        }
    }
}
.fesbHiddenSplashCard {
    pointer-events: none;
    opacity: 0;
    transition: none;
}
.animated {
    animation: fadeIn 0.8s forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

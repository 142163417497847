.fesbPageNotFound {
    position: fixed;
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 2rem;
    color: var(--colorPrimary);
    flex-direction: column;
    & > .returnText {
        margin-top: 1rem;
        font-size: 1rem;
    }
}

.fesbAcademicClassListElement {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 95%;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    box-sizing: border-box;
    padding: 5px 10px 5px 5px;
    text-transform: uppercase;
    text-decoration: none;
    cursor: pointer;
    color: var(--colorPrimaryLight);
    box-shadow: 0 0 6px 2px var(--colorPrimaryDark);
    margin: 3px 0;
    background: linear-gradient(
        0deg,
        var(--colorWhiteDarkBackground) 0%,
        var(--colorWhiteBackground) 50%,
        var(--colorWhiteDarkBackground) 100%
    );
    & > .row {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    & > .classRow {
        font-size: 1rem;
        color: var(--colorPrimaryDark);
    }
    &:hover {
        color: var(--colorWhiteBackground) !important;
        background: linear-gradient(0deg, var(--colorPrimaryDark) 0%, var(--colorPrimaryLight) 50%, var(--colorPrimaryDark) 100%);
        & > .classRow {
            font-size: 1rem;
            color: var(--colorWhiteBackground) !important;
        }
    }
}

.fesbEducatorListElement {
    display: flex;
    width: 95%;
    align-items: center;
    font-family: 'Exo 2', sans-serif;
    font-weight: 700;
    margin: 3px 0;
    box-sizing: border-box;
    padding: 5px 10px 5px 5px;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    color: var(--colorPrimaryDark);
    box-shadow: 0 0 6px 2px var(--colorPrimaryDark);
    background: linear-gradient(
        0deg,
        var(--colorWhiteDarkBackground) 0%,
        var(--colorWhiteBackground) 50%,
        var(--colorWhiteDarkBackground) 100%
    );
    &:hover {
        color: var(--colorWhiteBackground);
        background: linear-gradient(0deg, var(--colorPrimaryDark) 0%, var(--colorPrimaryLight) 50%, var(--colorPrimaryDark) 100%);
    }
    @media only screen and (max-width: 1000px) {
        height: auto;
    }
}
.fesbUserFrame {
    height: 25px;
    width: 25px;
    border-radius: 199px;
    display: flex;
    margin-right: 0.5rem;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
.fesbEducatorListElementGridSegment {
    display: grid;
    align-items: center;
    height: 100%;
}

body {
    --colorPrimaryDark: #004076;
    --colorPrimary: #00559b;
    --colorPrimaryLight: #1c6bb1;
    --colorGreyDark: #181818;
    --colorGrey: #282828;
    --colorGreyLight: #333333;
    --colorWhiteBackground: #dddddd;
    --colorWhiteDarkBackground: #bbbbbb;
    height: 100vh;
    min-width: 100%;
    margin: 0;
    font-family: 'Exo 2', sans-serif;
    position: absolute;
    top: 0;
    font-size: 12px;
    background-color: var(--colorGreyLight);
    background-image: url('./Resources/1.jpg');
    background-size: cover;
    background-attachment: fixed;
}
.fesbView {
    margin: 150px auto 0;
    width: 90vw;
    transition: width 0.4s;
    max-width: 1300px;
    height: 100vh;
    background-color: var(--colorWhiteBackground);
    box-shadow: 0 0 4px 3px var(--colorPrimaryDark);
    box-sizing: border-box;
    animation: zoomIn 0.7s forwards;
    @media only screen and (max-width: 1000px) {
        max-width: 90vw;
    }
}
@keyframes zoomIn {
    0% {
        transform: scale(0, 0);
        opacity: 0;
    }
    100% {
        transform: scale(1, 1);
        opacity: 1;
    }
}
.fesbSearchBoxAndContent {
    display: grid;
    grid-template-columns: 350px calc(100% - 350px);
    height: 100%;
    align-items: center;
    justify-items: center;
    @media only screen and (max-width: 1000px) {
        display: block;
        overflow: hidden;
    }
}
.fesbInfoPanel {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    @media only screen and (max-width: 1000px) {
        border-top-right-radius: 0px;
        height: calc(100% - 50px);
    }
}
.FesbLogoRow {
    position: fixed;
    right: 0;
    bottom: 0px;
    width: calc(100% - 20px);
    display: flex;
    justify-content: space-between;
    height: 60px;
    margin: 10px;
    & > * {
        height: 100%;
        & > * {
            height: 100%;
        }
    }
    & > .startLogo {
        height: 130px;
        align-self: flex-end;
        transition: opacity 0.4s;
        display: flex;
        @media only screen and (max-width: 1000px) {
            height: 14vh;
            justify-content: space-between;
            display: flex;
            width: 100%;
            & > * {
                margin-right: 0 !important;
            }
        }
        & > .logoContainer {
            height: 100%;
            display: flex;
            max-height: 20vw;
            flex-direction: column;
            align-items: center;
            font-size: 1.2rem;
            color: #555555;
            & > img {
                height: 80%;
            }
        }
        & > * {
            margin-right: 10px;
        }
        &.hidden {
            opacity: 0;
        }
        & > img {
            max-height: 20vw;
        }
    }
}
.fesbLogoStart {
    @media only screen and (max-width: 1000px) {
        position: fixed;
        top: 10vh;
        height: 10vh;
        width: 100%;
        display: flex;
        justify-content: center;
        left: 0;
    }
}
.fesbLogo {
    position: fixed;
    right: 10px;
    bottom: 10px;
    height: 8vh;
    // @media only screen and (max-width: 600px) {
    //     height: 15vw;
    // }
}
p {
    font-size: 13px;
}
a {
    color: black;
}

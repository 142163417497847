.staticHeaderLink {
    --elementWidth: 150px;
    width: var(--elementWidth);
    height: 40px;
    background-color: var(--colorGreyDark);
    position: absolute;
    &:before {
        content: '';
        position: absolute;
        right: var(--elementWidth);
        border-right: 20px solid var(--colorGreyDark);
        border-top: 20px solid #00000000;
        border-bottom: 20px solid #00000000;
    }
    &:after {
        content: '';
        position: absolute;
        width: 40px;
        left: var(--elementWidth);
        border-left: 20px solid var(--colorGreyDark);
        border-top: 20px solid #00000000;
        border-bottom: 20px solid #00000000;
    }
}
